import React from 'react';
import { 
    Box,
    Container, 
    Flex,
    Heading, 
} from '@chakra-ui/react';

import { PrismicRichText } from '@prismicio/react';

export default function ProjectContent(props) {
    return (
        <Container maxW="container.lg">
        <Heading as="h2" variant="h2" textAlign="center" fontSize="clamp(2rem, 4vw, 3rem)" mb="8">
            {props.projectTitle}   
        </Heading>
        <PrismicRichText
                        field={props.projectContent}
                    />
            {/* <Flex w="100%" h="100%" alignItems="center" flexWrap="wrap">
                <Box pr={{base: 0, lg: 16}} w={{base: "100%", lg: "50%"}}>
                    
                </Box>
                <Box w={{base: "100%", lg: "50%"}}>
                   
                </Box>
            </Flex> */}
        </Container>
    )
}