import * as React from "react"
import { Link as GatsbyLink } from "gatsby"
import { Link,
          Box 
        } from "@chakra-ui/react"
import { graphql } from "gatsby"
import Layout from '../components/layout'
import Seo from '../components/Seo'
import Slideshow from "../components/ProjectPage/Slideshow"
import ProjectContent from "../components/ProjectPage/ProjectContent"
import { ArrowBackIcon } from "@chakra-ui/icons"


const ProjectPageTemplate = ({ data, location }) => {
  
  const document = data.prismicProjectPage.data;
  const mobileProjectImages = data.mobileSlides.data.project_images;

  return (
    <Layout pageLocation={location}>
      <Seo title={document.seo_title.text} description={document.meta_description.text} />
        
        <Slideshow 
          projectImages={document.project_images}
          mobileImages={mobileProjectImages}
        />
        <ProjectContent 
          projectTitle={document.page_title}
          projectContent={document.page_content.richText}
        />
    <Box mb="12" pl="12" color="#e4bd0a">
      <Link as={GatsbyLink} to="/projects" fontWeight="bold" color="#e4bd0a" textTransform="uppercase">
        <ArrowBackIcon color="#e4bd0a" /> Back to Projects
      </Link>
    </Box>
    </Layout>
  )
}

export const query = graphql`
query ProjectPageQuery($uid: String!)
{
    prismicProjectPage(uid: {eq: $uid}) {
      uid
      data {
        project_images {
          image {
            alt
            copyright
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: TRACED_SVG, layout: FIXED, height: 670)
              }
            }
          }
        }
        page_title
        seo_title {
          text
        }
        meta_description {
          text
        }
        page_content {
          richText
        }
      }
    }
    mobileSlides: prismicProjectPage(uid: {eq: $uid}) {
      uid
      data {
        project_images {
          image {
            alt
            copyright
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: TRACED_SVG, layout: FIXED, height: 250)
              }
            }
          }
        }
        page_title
        page_content {
          richText
        }
      }
    }
  }
`

export default ProjectPageTemplate
